var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {
      key: _vm.$route.params.player_id,
      attrs: { id: "PlayerDetail", fluid: "", tag: "section" },
    },
    [
      _vm.displayModel == 0 &&
      _vm._usrFlagsSome({ key: "session.list", val: _vm.permissions.READ })
        ? _c(
            "FilterSideBar",
            {
              attrs: {
                filterIsEmpty: _vm.filterIsEmpty,
                filterIsActive: _vm.filterIsActive,
                refreshButton: "",
                width: _vm.isMobile ? "" : "400px",
              },
              on: { clear: _vm.clearFilter, refresh: _vm.reloadSessions },
            },
            [
              _c("SessionListForm", {
                ref: "filter",
                on: { filter: _vm.useFilter },
                model: {
                  value: _vm.filter,
                  callback: function ($$v) {
                    _vm.filter = $$v
                  },
                  expression: "filter",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-snackbar",
            {
              staticStyle: {
                position: "fixed",
                bottom: "20px",
                "text-align": "center",
              },
              attrs: {
                "content-class": "kajot-text--text",
                app: "",
                transition: "slide-y-reverse-transition",
                color: "menu_background",
                timeout: _vm.timeout,
              },
              scopedSlots: _vm._u([
                {
                  key: "action",
                  fn: function () {
                    return [
                      _c(
                        "v-btn",
                        {
                          attrs: { fab: "", text: "", "x-small": "" },
                          on: {
                            click: function ($event) {
                              _vm.copied = false
                            },
                          },
                        },
                        [
                          _c(
                            "v-icon",
                            { attrs: { color: "kajot-text", small: "" } },
                            [_vm._v(" mdi-close ")]
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
              model: {
                value: _vm.copied,
                callback: function ($$v) {
                  _vm.copied = $$v
                },
                expression: "copied",
              },
            },
            [
              _c(
                "div",
                { staticClass: "align-center" },
                [
                  _c("v-icon", { attrs: { color: "info" } }, [
                    _vm._v("mdi-information"),
                  ]),
                  _vm._v(" Session copied to clipboard. "),
                ],
                1
              ),
            ]
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6" } },
            [
              _c("KeyValueCard2", {
                attrs: {
                  loading: _vm.detailLoading,
                  item: _vm.detail,
                  cols: _vm.filteredPlayerHeaders,
                  title: _vm.playerDetailTitle,
                  color: "secondary",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: `item.sum_bet.value`,
                      fn: function ({ item }) {
                        return [
                          _vm._v(
                            " " + _vm._s(_vm._f("Number")(item.sum_bet)) + " "
                          ),
                        ]
                      },
                    },
                    {
                      key: `item.sum_win.value`,
                      fn: function ({ item }) {
                        return [
                          _vm._v(
                            " " + _vm._s(_vm._f("Number")(item.sum_win)) + " "
                          ),
                        ]
                      },
                    },
                    {
                      key: `item.balance.value`,
                      fn: function ({ item }) {
                        return [
                          _vm._v(
                            " " + _vm._s(_vm._f("Number")(item.balance)) + " "
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6" } },
            [
              _c("KeyValueCard2", {
                attrs: {
                  loading: _vm.detailLoading,
                  item: _vm.detail,
                  cols: _vm.playerStatsRows,
                  title: "Player Stats",
                  color: "secondary",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: `item.sessions_count.value`,
                      fn: function ({ item }) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("Number")(item.sessions_count, {
                                  style: "decimal",
                                  minimumFractionDigits: 0,
                                })
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: `item.games_count.value`,
                      fn: function ({ item }) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("Number")(item.games_count, {
                                  style: "decimal",
                                  minimumFractionDigits: 0,
                                })
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: `item.bet_per_game.value`,
                      fn: function ({ item }) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(_vm._f("Number")(item.bet_per_game)) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: `item.games_per_session.value`,
                      fn: function ({ item }) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("Number")(item.games_per_session, {
                                  style: "decimal",
                                  minimumFractionDigits: 0,
                                })
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            [
              _vm._usrFlagsSome({
                key: "session.list",
                val: _vm.permissions.READ,
              })
                ? _c(
                    "v-card",
                    { staticClass: "v-card--material true pa-3 px-5 py-3" },
                    [
                      _c(
                        "card-heading",
                        {
                          attrs: {
                            paddingLeft: "0",
                            paddingRight: "0",
                            paddingTop: "0",
                            paddingBottom: "0",
                          },
                        },
                        [
                          _c(
                            "v-tabs",
                            {
                              attrs: {
                                height: "74",
                                color: `primary ${
                                  _vm.$vuetify.theme.dark ? "" : "darken-2"
                                }`,
                                "background-color": "transparent",
                              },
                              model: {
                                value: _vm.displayModel,
                                callback: function ($$v) {
                                  _vm.displayModel = $$v
                                },
                                expression: "displayModel",
                              },
                            },
                            [
                              _c(
                                "v-tab",
                                {
                                  class:
                                    _vm.displayModel === 0
                                      ? `primary--text ${
                                          _vm.$vuetify.theme.dark
                                            ? ""
                                            : "text--darken-2"
                                        }`
                                      : "kajot-text--text",
                                },
                                [_vm._v(" Sessions ")]
                              ),
                              _c(
                                "v-tab",
                                {
                                  class:
                                    _vm.displayModel === 1
                                      ? `primary--text ${
                                          _vm.$vuetify.theme.dark
                                            ? ""
                                            : "text--darken-2"
                                        }`
                                      : "kajot-text--text",
                                  attrs: {
                                    disabled: _vm.playerOffers.length === 0,
                                  },
                                },
                                [_vm._v(" Offers ")]
                              ),
                            ],
                            1
                          ),
                          _c("v-spacer"),
                          _vm._usrFlagsSome({
                            key: `session.list`,
                            val: _vm.permissions.CREATE,
                          })
                            ? _c("ConfirmDialog2", {
                                attrs: {
                                  persistent: "",
                                  confirmBtnText: `Export anyway`,
                                  confirmBtnColor: "error",
                                  cancelBtnColor: "kajot-text",
                                  cancelBtnText: "Back",
                                  shouldShow:
                                    _vm.filterIsEmpty && !_vm.dontShow,
                                },
                                on: { confirm: _vm.onExportOpen },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "body",
                                      fn: function () {
                                        return [
                                          _c("b", [
                                            _vm._v(
                                              " Exporting unfiltered data may significantly impact server load and increase wait times. "
                                            ),
                                          ]),
                                          _c("br"),
                                          _vm._v(" Do you wish to proceed? "),
                                          _c(
                                            "v-row",
                                            {
                                              staticClass:
                                                "d-flex flex-row align-center justify-space-around",
                                            },
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "1" } },
                                                [
                                                  _c("v-checkbox", {
                                                    model: {
                                                      value: _vm.dontShow,
                                                      callback: function ($$v) {
                                                        _vm.dontShow = $$v
                                                      },
                                                      expression: "dontShow",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c("v-col", [
                                                _c("span", [
                                                  _vm._v(
                                                    "Don't ask again in this session"
                                                  ),
                                                ]),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                    {
                                      key: "default",
                                      fn: function ({ showConfirm }) {
                                        return [
                                          _vm.displayModel === 0
                                            ? _c(
                                                "v-btn",
                                                {
                                                  staticClass: "mr-12",
                                                  attrs: {
                                                    fab: "",
                                                    color: "primary",
                                                    "x-small": "",
                                                  },
                                                  on: { click: showConfirm },
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v(
                                                      "mdi-arrow-down-bold"
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1622571973
                                ),
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-carousel",
                            {
                              attrs: {
                                height: "100%",
                                "hide-delimiters": "",
                                "show-arrows": false,
                              },
                              model: {
                                value: _vm.displayModel,
                                callback: function ($$v) {
                                  _vm.displayModel = $$v
                                },
                                expression: "displayModel",
                              },
                            },
                            [
                              _c(
                                "v-carousel-item",
                                [
                                  _c("v-data-table", {
                                    staticClass: "elevation-1",
                                    attrs: {
                                      "must-sort": "",
                                      "sort-by": "start_time",
                                      "sort-desc": true,
                                      headers: _vm.filteredHeaders,
                                      items: _vm.sessionList,
                                      "item-class": _vm.itemClass,
                                      options: _vm.options,
                                      "server-items-length":
                                        _vm.options.itemsPerPage,
                                      "hide-default-footer": "",
                                      loading: _vm.loading,
                                      "footer-props": {
                                        "items-per-page-options": [
                                          5, 10, 15, 25, 50, 100,
                                        ],
                                      },
                                    },
                                    on: {
                                      "update:options": function ($event) {
                                        _vm.options = $event
                                      },
                                      "click:row": _vm.handleClick,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "top",
                                          fn: function ({
                                            options,
                                            updateOptions,
                                          }) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex justify-end mr-4",
                                                },
                                                [
                                                  _c("InfinitePagination", {
                                                    attrs: {
                                                      footer: _vm.footerProps,
                                                      dense: _vm.dense,
                                                      options: options,
                                                    },
                                                    on: {
                                                      "update:dense": function (
                                                        $event
                                                      ) {
                                                        _vm.dense = $event
                                                      },
                                                      "update:options":
                                                        updateOptions,
                                                      pagination:
                                                        _vm.mxPaginationHandler,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                        {
                                          key: "foot",
                                          fn: function ({
                                            options,
                                            updateOptions,
                                          }) {
                                            return [
                                              _c("tfoot", [
                                                _c("tr", [
                                                  _c(
                                                    "td",
                                                    {
                                                      attrs: {
                                                        colspan: "100%",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex justify-end",
                                                        },
                                                        [
                                                          _c(
                                                            "InfinitePagination",
                                                            {
                                                              attrs: {
                                                                footer:
                                                                  _vm.footerProps,
                                                                dense:
                                                                  _vm.dense,
                                                                options:
                                                                  options,
                                                              },
                                                              on: {
                                                                "update:dense":
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    _vm.dense =
                                                                      $event
                                                                  },
                                                                "update:options":
                                                                  updateOptions,
                                                                pagination:
                                                                  _vm.mxPaginationHandler,
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                              ]),
                                            ]
                                          },
                                        },
                                        {
                                          key: `item.external`,
                                          fn: function ({ item }) {
                                            return [
                                              _c(
                                                "span",
                                                {
                                                  attrs: {
                                                    title:
                                                      _vm.shouldShortenString(
                                                        item.external
                                                      )
                                                        ? item.external
                                                        : undefined,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm._f("ellipsis")(
                                                          item.external
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                        {
                                          key: `item.session_mongo_id`,
                                          fn: function ({ item }) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row align-center",
                                                },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass:
                                                        "elevation-0 transparent",
                                                      attrs: {
                                                        icon: "",
                                                        small: "",
                                                        color: "kajot-text",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          $event.stopPropagation()
                                                          return _vm.copySession(
                                                            item.session_mongo_id
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { small: "" },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "mdi-content-copy"
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.session_mongo_id
                                                      )
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                        {
                                          key: `item.external_ticket`,
                                          fn: function ({ item }) {
                                            return [
                                              _c(
                                                "span",
                                                {
                                                  attrs: {
                                                    title:
                                                      _vm.shouldShortenString(
                                                        item.external_ticket
                                                      )
                                                        ? item.external_ticket
                                                        : undefined,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm._f("ellipsis")(
                                                          item.external_ticket
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                        {
                                          key: `item.start_time`,
                                          fn: function ({ item }) {
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("Date")(
                                                      item.start_time
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          },
                                        },
                                        {
                                          key: `item.end_time`,
                                          fn: function ({ item }) {
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("Date")(
                                                      item.end_time
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          },
                                        },
                                        {
                                          key: `item.sum_bet`,
                                          fn: function ({ item }) {
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("Number")(
                                                      item.sum_bet,
                                                      {
                                                        currency: item.currency,
                                                      }
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          },
                                        },
                                        {
                                          key: `item.sum_win`,
                                          fn: function ({ item }) {
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("Number")(
                                                      item.sum_win,
                                                      {
                                                        currency: item.currency,
                                                      }
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          },
                                        },
                                        {
                                          key: `item.netto`,
                                          fn: function ({ item }) {
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("Number")(
                                                      item.netto,
                                                      {
                                                        currency: item.currency,
                                                      }
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          },
                                        },
                                        {
                                          key: `item.finished`,
                                          fn: function ({ item }) {
                                            return [
                                              item.end_time
                                                ? _c("v-icon", [
                                                    _vm._v("mdi-check"),
                                                  ])
                                                : _c(
                                                    "v-icon",
                                                    {
                                                      attrs: { color: "error" },
                                                    },
                                                    [_vm._v(" mdi-close ")]
                                                  ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-carousel-item",
                                [
                                  _c("v-data-table", {
                                    attrs: {
                                      loading: _vm.loading,
                                      "item-class": _vm.itemClass,
                                      items: _vm.playerOffers,
                                      "show-first-last-page": true,
                                      "footer-props": {
                                        "items-per-page-options": [
                                          5, 10, 15, 25, 50, 100,
                                        ],
                                      },
                                      options: _vm.offerOptions,
                                      headers: _vm.offerHeaders,
                                    },
                                    on: {
                                      "update:options": function ($event) {
                                        _vm.offerOptions = $event
                                      },
                                      "click:row": _vm.offerClick,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "top",
                                          fn: function ({ pagination }) {
                                            return [
                                              _c(
                                                "v-data-footer",
                                                _vm._b(
                                                  {
                                                    staticClass: "anton",
                                                    attrs: {
                                                      "show-first-last-page": false,
                                                      "prev-icon":
                                                        "mdi-chevron-left",
                                                      "next-icon":
                                                        "mdi-chevron-right",
                                                      "items-per-page-text":
                                                        "Rows per page:",
                                                      options: _vm.offerOptions,
                                                      pagination: pagination,
                                                    },
                                                    on: {
                                                      "update:options":
                                                        function ($event) {
                                                          _vm.offerOptions =
                                                            $event
                                                        },
                                                    },
                                                  },
                                                  "v-data-footer",
                                                  _vm.footerProps,
                                                  false
                                                )
                                              ),
                                            ]
                                          },
                                        },
                                        {
                                          key: `item.valid_from`,
                                          fn: function ({ item }) {
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("Date")(
                                                      item.valid_from
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          },
                                        },
                                        {
                                          key: `item.valid_to`,
                                          fn: function ({ item }) {
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("Date")(
                                                      item.valid_to
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          },
                                        },
                                        {
                                          key: `item.status`,
                                          fn: function ({ item }) {
                                            return [
                                              item.is_removed
                                                ? _c(
                                                    "v-chip",
                                                    {
                                                      attrs: {
                                                        small: "",
                                                        color: "error",
                                                      },
                                                    },
                                                    [_vm._v(" Closed ")]
                                                  )
                                                : new Date(
                                                    item.valid_from
                                                  ).getTime() > _vm.timeNow
                                                ? _c(
                                                    "v-chip",
                                                    {
                                                      attrs: {
                                                        small: "",
                                                        color: "warning",
                                                      },
                                                    },
                                                    [_vm._v(" Pre-Activation ")]
                                                  )
                                                : new Date(
                                                    item.valid_to
                                                  ).getTime() < _vm.timeNow
                                                ? _c(
                                                    "v-chip",
                                                    {
                                                      attrs: {
                                                        small: "",
                                                        color: "warning",
                                                      },
                                                    },
                                                    [_vm._v(" Expired ")]
                                                  )
                                                : _c(
                                                    "v-chip",
                                                    {
                                                      attrs: {
                                                        small: "",
                                                        color: "success",
                                                      },
                                                    },
                                                    [_vm._v(" Active ")]
                                                  ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500px" },
          on: {
            "click:outside": _vm.exportClose,
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              )
                return null
              return _vm.exportClose.apply(null, arguments)
            },
          },
          model: {
            value: _vm.exportDialog,
            callback: function ($$v) {
              _vm.exportDialog = $$v
            },
            expression: "exportDialog",
          },
        },
        [
          _c("ExportDialog", {
            ref: "exportDialog",
            attrs: {
              builder: "paginatedObject",
              delimiter: ";",
              indeterminate: "",
              data: _vm.exportData,
              filename: `${_vm.detail.player_mongo_id}-sessions`,
            },
            on: { close: _vm.exportClose },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }