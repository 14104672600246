<template>
	<v-container
		:key="$route.params.player_id"
		id="PlayerDetail"
		fluid
		tag="section">
		<FilterSideBar
			v-if="
				displayModel == 0 &&
				_usrFlagsSome({ key: 'session.list', val: permissions.READ })
			"
			:filterIsEmpty="filterIsEmpty"
			:filterIsActive="filterIsActive"
			@clear="clearFilter"
			refreshButton
			@refresh="reloadSessions"
			:width="isMobile ? '' : '400px'">
			<SessionListForm
				ref="filter"
				@filter="useFilter"
				v-model="filter" />
		</FilterSideBar>
		<v-row dense>
			<v-snackbar
				style="position: fixed; bottom: 20px; text-align: center"
				content-class="kajot-text--text"
				app
				transition="slide-y-reverse-transition"
				color="menu_background"
				:timeout="timeout"
				v-model="copied">
				<div class="align-center">
					<v-icon color="info">mdi-information</v-icon>
					Session copied to clipboard.
				</div>
				<template #action>
					<v-btn
						fab
						text
						x-small
						@click="copied = false">
						<v-icon
							color="kajot-text"
							small>
							mdi-close
						</v-icon>
					</v-btn>
				</template>
			</v-snackbar>
			<v-col
				cols="12"
				md="6">
				<KeyValueCard2
					:loading="detailLoading"
					:item="detail"
					:cols="filteredPlayerHeaders"
					:title="playerDetailTitle"
					color="secondary">
					<template #[`item.sum_bet.value`]="{ item }">
						{{ item.sum_bet | Number }}
					</template>
					<template #[`item.sum_win.value`]="{ item }">
						{{ item.sum_win | Number }}
					</template>
					<template #[`item.balance.value`]="{ item }">
						{{ item.balance | Number }}
					</template>
				</KeyValueCard2>
			</v-col>

			<v-col
				cols="12"
				md="6">
				<KeyValueCard2
					:loading="detailLoading"
					:item="detail"
					:cols="playerStatsRows"
					:title="'Player Stats'"
					color="secondary">
					<template #[`item.sessions_count.value`]="{ item }">
						{{
							item.sessions_count
								| Number({ style: "decimal", minimumFractionDigits: 0 })
						}}
					</template>
					<template #[`item.games_count.value`]="{ item }">
						{{
							item.games_count
								| Number({ style: "decimal", minimumFractionDigits: 0 })
						}}
					</template>
					<template #[`item.bet_per_game.value`]="{ item }">
						{{ item.bet_per_game | Number }}
					</template>
					<template #[`item.games_per_session.value`]="{ item }">
						{{
							item.games_per_session
								| Number({ style: "decimal", minimumFractionDigits: 0 })
						}}
					</template>
				</KeyValueCard2>
			</v-col>
		</v-row>

		<v-row dense>
			<v-col>
				<v-card
					v-if="_usrFlagsSome({ key: 'session.list', val: permissions.READ })"
					class="v-card--material true pa-3 px-5 py-3">
					<card-heading
						paddingLeft="0"
						paddingRight="0"
						paddingTop="0"
						paddingBottom="0">
						<v-tabs
							height="74"
							:color="`primary ${$vuetify.theme.dark ? '' : 'darken-2'}`"
							v-model="displayModel"
							@change="onDisplayChange"
							background-color="transparent">
							<v-tab
								:class="
									displayModel === 0
										? `primary--text ${
												$vuetify.theme.dark ? '' : 'text--darken-2'
										  }`
										: 'kajot-text--text'
								">
								Sessions
							</v-tab>
							<v-tab
								v-if="isAdmin"
								:class="
									displayModel === 1
										? `primary--text ${
												$vuetify.theme.dark ? '' : 'text--darken-2'
										  }`
										: 'kajot-text--text'
								">
								Rounds
							</v-tab>
							<v-tab
								:disabled="playerOffers.length === 0"
								:class="
									displayModel === 2
										? `primary--text ${
												$vuetify.theme.dark ? '' : 'text--darken-2'
										  }`
										: 'kajot-text--text'
								">
								Offers
							</v-tab>
						</v-tabs>
						<v-spacer></v-spacer>
						<ConfirmDialog2
							persistent
							v-if="
								_usrFlagsSome({
									key: `session.list`,
									val: permissions.CREATE,
								})
							"
							:confirmBtnText="`Export anyway`"
							:confirmBtnColor="'error'"
							:cancelBtnColor="'kajot-text'"
							:cancelBtnText="'Back'"
							:shouldShow="filterIsEmpty && !dontShow"
							@confirm="onExportOpen">
							<template v-slot:body>
								<b>
									Exporting unfiltered data may significantly impact server load
									and increase wait times.
								</b>
								<br />
								Do you wish to proceed?
								<v-row
									class="d-flex flex-row align-center justify-space-around">
									<v-col cols="1">
										<v-checkbox v-model="dontShow"></v-checkbox>
									</v-col>
									<v-col>
										<span>Don't ask again in this session</span>
									</v-col>
								</v-row>
							</template>
							<template v-slot:default="{ showConfirm }">
								<v-btn
									v-if="displayModel === 0"
									class="mr-12"
									fab
									color="primary"
									x-small
									@click="showConfirm">
									<v-icon>mdi-arrow-down-bold</v-icon>
								</v-btn>
							</template>
						</ConfirmDialog2>
					</card-heading>
					<v-card-text>
						<v-carousel
							height="100%"
							v-model="displayModel"
							hide-delimiters
							:show-arrows="false">
							<v-carousel-item>
								<v-data-table
									must-sort
									sort-by="start_time"
									:sort-desc="true"
									:headers="filteredHeaders"
									:items="sessionList"
									:item-class="itemClass"
									:options.sync="options"
									:server-items-length="options.itemsPerPage"
									hide-default-footer
									:loading="loading"
									:footer-props="{
										'items-per-page-options': [5, 10, 15, 25, 50, 100],
									}"
									class="elevation-1"
									@click:row="handleClick">
									<template #top="{ options, updateOptions }">
										<div class="d-flex justify-end mr-4">
											<InfinitePagination
												:footer="footerProps"
												:dense.sync="dense"
												:options="options"
												@update:options="updateOptions"
												@pagination="mxPaginationHandler"></InfinitePagination>
										</div>
									</template>
									<template #foot="{ options, updateOptions }">
										<tfoot>
											<tr>
												<td colspan="100%">
													<div class="d-flex justify-end">
														<InfinitePagination
															:footer="footerProps"
															:dense.sync="dense"
															:options="options"
															@update:options="updateOptions"
															@pagination="
																mxPaginationHandler
															"></InfinitePagination>
													</div>
												</td>
											</tr>
										</tfoot>
									</template>
									<template v-slot:[`item.external`]="{ item }">
										<span
											:title="
												shouldShortenString(item.external)
													? item.external
													: undefined
											">
											{{ item.external | ellipsis }}
										</span>
									</template>
									<template #[`item.session_mongo_id`]="{ item }">
										<div class="d-flex flex-row align-center">
											<v-btn
												icon
												small
												class="elevation-0 transparent"
												color="kajot-text"
												@click.prevent.stop="
													copySession(item.session_mongo_id)
												">
												<v-icon small>mdi-content-copy</v-icon>
											</v-btn>
											<span>{{ item.session_mongo_id }}</span>
										</div>
									</template>
									<template v-slot:[`item.external_ticket`]="{ item }">
										<span
											:title="
												shouldShortenString(item.external_ticket)
													? item.external_ticket
													: undefined
											">
											{{ item.external_ticket | ellipsis }}
										</span>
									</template>
									<template v-slot:[`item.start_time`]="{ item }">
										{{ item.start_time | Date }}
									</template>
									<template v-slot:[`item.end_time`]="{ item }">
										{{ item.end_time | Date }}
									</template>
									<template v-slot:[`item.sum_bet`]="{ item }">
										{{ item.sum_bet | Number({ currency: item.currency }) }}
									</template>
									<template v-slot:[`item.sum_win`]="{ item }">
										{{ item.sum_win | Number({ currency: item.currency }) }}
									</template>
									<template v-slot:[`item.netto`]="{ item }">
										{{ item.netto | Number({ currency: item.currency }) }}
									</template>
									<template v-slot:[`item.finished`]="{ item }">
										<v-icon v-if="item.end_time">mdi-check</v-icon>
										<v-icon
											v-else
											color="error">
											mdi-close
										</v-icon>
									</template>
								</v-data-table>
							</v-carousel-item>
							<v-carousel-item>
								<v-data-table
									must-sort
									sort-by="start_time"
									:sort-desc="true"
									:headers="roundHeadersFiltered"
									:items="roundList"
									:item-class="itemClass"
									:options.sync="options"
									:server-items-length="options.itemsPerPage"
									hide-default-footer
									:loading="loading"
									:footer-props="{
										'items-per-page-options': [5, 10, 15, 25, 50, 100],
									}"
									class="elevation-1"
									@click:row="handleClick">
									<template #top="{ options, updateOptions }">
										<div class="d-flex justify-end mr-4">
											<InfinitePagination
												:footer="footerProps"
												:dense.sync="dense"
												:options="options"
												prefix="rounds"
												@update:options="updateOptions"
												@pagination="mxPaginationHandler"></InfinitePagination>
										</div>
									</template>
									<template #foot="{ options, updateOptions }">
										<tfoot>
											<tr>
												<td colspan="100%">
													<div class="d-flex justify-end">
														<InfinitePagination
															:footer="footerProps"
															:dense.sync="dense"
															:options="options"
															@update:options="updateOptions"
															@pagination="
																mxPaginationHandler
															"></InfinitePagination>
													</div>
												</td>
											</tr>
										</tfoot>
									</template>
									<template v-slot:[`item.external`]="{ item }">
										<span
											:title="
												shouldShortenString(item.external)
													? item.external
													: undefined
											">
											{{ item.external | ellipsis }}
										</span>
									</template>
									<template #[`item.session_mongo_id`]="{ item }">
										<div class="d-flex flex-row align-center">
											<v-btn
												icon
												small
												class="elevation-0 transparent"
												color="kajot-text"
												@click.prevent.stop="
													copySession(item.session_mongo_id)
												">
												<v-icon small>mdi-content-copy</v-icon>
											</v-btn>
											<span>{{ item.session_mongo_id }}</span>
										</div>
									</template>
									<template v-slot:[`item.external_ticket`]="{ item }">
										<span
											:title="
												shouldShortenString(item.external_ticket)
													? item.external_ticket
													: undefined
											">
											{{ item.external_ticket | ellipsis }}
										</span>
									</template>
									<template v-slot:[`item.start_time`]="{ item }">
										{{ item.start_time | Date }}
									</template>
									<template v-slot:[`item.end_time`]="{ item }">
										{{ item.end_time | Date }}
									</template>
									<template v-slot:[`item.sum_bet`]="{ item }">
										{{ item.sum_bet | Number({ currency: item.currency }) }}
									</template>
									<template v-slot:[`item.sum_win`]="{ item }">
										{{ item.sum_win | Number({ currency: item.currency }) }}
									</template>
									<template v-slot:[`item.netto`]="{ item }">
										{{ item.netto | Number({ currency: item.currency }) }}
									</template>
									<template v-slot:[`item.finished`]="{ item }">
										<v-icon v-if="item.end_time">mdi-check</v-icon>
										<v-icon
											v-else
											color="error">
											mdi-close
										</v-icon>
									</template>
								</v-data-table>
							</v-carousel-item>
							<v-carousel-item>
								<v-data-table
									:loading="loading"
									:item-class="itemClass"
									:items="playerOffers"
									:show-first-last-page="true"
									:footer-props="{
										'items-per-page-options': [5, 10, 15, 25, 50, 100],
									}"
									:options.sync="offerOptions"
									:headers="offerHeaders"
									@click:row="offerClick">
									<template #top="{ pagination }">
										<v-data-footer
											class="anton"
											:show-first-last-page="false"
											prev-icon="mdi-chevron-left"
											next-icon="mdi-chevron-right"
											items-per-page-text="Rows per page:"
											:options.sync="offerOptions"
											v-bind="footerProps"
											:pagination="pagination"></v-data-footer>
									</template>
									<template v-slot:[`item.valid_from`]="{ item }">
										{{ item.valid_from | Date }}
									</template>
									<template v-slot:[`item.valid_to`]="{ item }">
										{{ item.valid_to | Date }}
									</template>
									<template v-slot:[`item.status`]="{ item }">
										<v-chip
											small
											v-if="item.is_removed"
											color="error">
											Closed
										</v-chip>
										<v-chip
											small
											color="warning"
											v-else-if="new Date(item.valid_from).getTime() > timeNow">
											Pre-Activation
										</v-chip>
										<v-chip
											small
											color="warning"
											v-else-if="new Date(item.valid_to).getTime() < timeNow">
											Expired
										</v-chip>
										<v-chip
											small
											v-else
											color="success">
											Active
										</v-chip>
									</template>
								</v-data-table>
							</v-carousel-item>
						</v-carousel>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
		<v-dialog
			@click:outside="exportClose"
			@keydown.esc="exportClose"
			max-width="500px"
			v-model="exportDialog">
			<ExportDialog
				@close="exportClose"
				builder="paginatedObject"
				delimiter=";"
				indeterminate
				:data="exportData"
				:filename="`${detail.player_mongo_id}-sessions`"
				ref="exportDialog"></ExportDialog>
		</v-dialog>
	</v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import store from "../../store";

import SessionListForm from "../../components/sessions/SessionListForm.vue";
import CardHeading from "../../components/shared/CardHeading.vue";
import KeyValueCard2 from "../../components/shared/keyValueCard2.vue";
import permissions from "../../mixins/permissions";
import { shouldShortenString, isMobile } from "../../constants/helpers";
import FilterSideBar from "../../components/shared/FilterSideBar.vue";
import _ from "lodash";
import table2 from "../../mixins/table2";
import ExportDialog from "../../components/shared/ExportDialog.vue";
import ConfirmDialog2 from "../../components/shared/ConfirmDialog2.vue";
import InfinitePagination from "../../components/shared/InfinitePagination.vue";

export default {
	components: {
		SessionListForm,
		FilterSideBar,
		CardHeading,
		KeyValueCard2,
		ExportDialog,
		ConfirmDialog2,
		InfinitePagination,
	},
	mixins: [permissions, table2],
	data() {
		return {
			dontShow: sessionStorage.getItem("logan2:exportDialog:hide") ?? false,
			exportDialog: false,
			timeout: 1000,
			copied: false,
			displayModel: 0,

			timeNow: Date.now(),

			filter: {
				min_bet: undefined,
				max_bet: undefined,
				min_win: undefined,
				max_win: undefined,
				min_games: undefined,
				max_games: undefined,
				date_from: undefined,
				date_to: undefined,
				currency: [],
				games: [],
			},
			sectionColor: "secondary",
			detailLoading: false,
			offerOptions: {},
			sessionOptions: {},
			roundOptions: {},
			total_records: 0,
			sessionPage: 0,
			loading: false,
			sessionFilterExpanded: false,
			offerHeaders: [
				{ text: "Offer code", value: "offer_code", align: "left" },
				{ text: "Offer name", value: "name", align: "left" },
				{ text: "Valid from", value: "valid_from", align: "center" },
				{ text: "Valid to", value: "valid_to", align: "center" },
				{ text: "Spins", value: "spins", align: "center" },
				{ text: "Spins left", value: "spins_left", align: "center" },
				{ text: "Status", value: "status", align: "center" },
			],
			sessionsHeaders: [
				{ text: "Session ID", value: "session_mongo_id", align: "center" },
				{
					text: "External",
					value: "external",
					align: "center",
					hide: () => !this.sessionList[0]?.external,
				},
				{
					text: "External ticket",
					value: "external_ticket",
					align: "center",
					hide: () => !this.sessionList[0]?.external,
				},
				{ text: "Game", value: "game_name", align: "left" },
				{ text: "Start Time", value: "start_time", align: "center" },
				{ text: "End Time", value: "end_time", align: "center" },
				{ text: "Σ Bet", value: "sum_bet", align: "right" },
				{ text: "Σ Win", value: "sum_win", align: "right" },
				{ text: "Netto", value: "netto", align: "right" },
				{ text: "Round Count", value: "rounds_count", align: "center" },
				{
					text: "Finished",
					value: "finished",
					align: "center",
					sortable: false,
				},
			],
			roundHeaders: [
				{ text: "", value: "copy", align: "center", sortable: false },
				{ text: "Round ID", value: "round_id", align: "left" },
				{ text: "Start Time", value: "start_time", align: "center" },
				{
					text: "External Round",
					value: "external_round",
					align: "center",
					sortable: false,
					hide: () => this.detail.external === null,
				},
				{
					text: "External Bet",
					value: "external_bet",
					align: "center",
					sortable: false,
				},
				{
					text: "External Win",
					value: "external_win",
					align: "center",
					sortable: false,
				},
				{ text: "Bet", value: "bet", align: "right" },
				{ text: "Win", value: "win", align: "right" },
				{ text: "Netto", value: "netto", align: "right" },
				// { text: "Hand", value: "_hand", align: "center" },
				{
					text: "Orientation",
					value: "orientation",
					align: "center",
					sortable: false,
					hide: () => this.detail._device === "Desktop",
				},
				{
					text: "Finished",
					value: "finished",
					align: "center",
					sortable: false,
				},
				{
					text: "Flags",
					value: "flags",
					align: "center",
					sortable: false,
				},
				{ text: "Actions", value: "actions", align: "center", sortable: false },
			],
			playerDetailRows: [
				{
					label: "Nick",
					key: "nick",
					hide: () => !this.detail.nick,
				},
				{ label: "Player ID", key: "player_mongo_id" },
				{ label: "Sum Bet", key: "sum_bet" },
				{ label: "Sum Win", key: "sum_win" },
			],
			playerStatsRows: [
				{ label: "Total Sessions", key: "sessions_count" },
				{ label: "Total Games", key: "games_count" },
				{ label: "Bet/Game", key: "bet_per_game" },
				{ label: "Game/Session", key: "games_per_session" },
			],
		};
	},
	async beforeRouteEnter(to, from, next) {
		await store.dispatch("freeGames/loadOffersPerPlayer", {
			playerId: to.params.player_id,
			casino_id: to.params.casino,
			noErr: true,
		});

		const enter = await store.dispatch("players2/loadDetail", {
			player_id: to.params.player_id,
			casino_id: to.params.casino,
		});

		if (to.params.player_id === undefined || enter === false) {
			return next(new Error("Player not found"));
		}

		next();
	},
	computed: {
		isMobile,
		isAdmin() {
			return this._usrFlagsSome({
				key: [
					"management.users.list",
					"management.users.detail",
					"management.groups.list",
					"management.groups.detail",
				],
				val:
					this.permissions.READ |
					this.permissions.EDIT |
					this.permissions.CREATE |
					this.permissions.DELETE,
			});
		},
		filteredPlayerHeaders() {
			return this.playerDetailRows.filter((el) => !el.hide?.());
		},
		options: {
			get() {
				if (this.displayModel === 0) {
					return this.sessionOptions;
				} else if (this.displayModel === 1) {
					return this.roundOptions;
				} else {
					return this.offerOptions;
				}
			},
			set(value) {
				if (this.displayModel === 0) {
					this.sessionOptions = value;
				} else if (this.displayModel === 1) {
					this.roundOptions = value;
				}
			},
		},
		roundHeadersFiltered() {
			return this.roundHeaders.filter((el) => !el.hide?.());
		},
		exportData() {
			return [
				[
					["session_mongo_id", "Session Mongo Id"],
					["player_mongo_id", "Player Mongo Id"],
					["game_name", "Game Name"],
					["game_id", "Game Id"],
					["player", "Player"],
					["start_time", "Start Time"],
					["end_time", "End Time"],
					["internal", "Internal"],
					["external", "External"],
					["currency", "Currency"],
					["sum_bet", "Sum Bet"],
					["sum_win", "Sum Win"],
					["netto", "Netto"],
					["rounds_count", "Rounds Count"],
					["external_ticket_id", "External Ticket Id"],
				],
				{
					filter: {
						player_mongo_id: this.detail.player_mongo_id,
						...this.filter,
					},
					config: this.qsConfig,
					pagi: this.getPagination,
					url: `${this.loganUrl}/api/v2/casinos/${this.currentCasino}/sessions`,
					authToken: this.authToken,
				},
			];
		},
		filterIsEmpty() {
			return (
				_.isEqual(this.filter, {
					min_bet: undefined,
					max_bet: undefined,
					min_win: undefined,
					max_win: undefined,
					min_games: undefined,
					max_games: undefined,
					date_from: undefined,
					date_to: undefined,
					currency: [],
					games: [],
				}) ||
				_.isEqual(this.filter, {
					currency: [],
					games: [],
				})
			);
		},
		filterIsActive() {
			return _.isEqual(this.filter, this.sessionFilter);
		},
		rowIsClickable() {
			return this._usrFlagsSome({
				key: "session.detail",
				val: this.permissions.READ,
			});
		},
		...mapGetters(["currentCasino", "loganUrl"]),
		...mapGetters("players2", {
			detail: "detail",
		}),
		...mapGetters("apiCall", { authToken: "accessToken" }),
		...mapGetters("breadcrumbs", {
			lastCrumb: "lastCrumb",
			shortCrumbs: "shortCrumbsFromHistory",
		}),
		...mapGetters("freeGames", {
			playerOffers: "playerOffers",
		}),
		...mapGetters("sessions2", {
			qsConfig: "getQsConfig",
			getPagination: "pagination",
			sessionList: "list",
			sessionFilter: "filter",
		}),
		...mapGetters("rounds2", {
			qsConfig: "getQsConfig",
			roundList: "list",
			roundFilter: "filter",
		}),
		playerDetailTitle() {
			return `Player : ${this.detail.nick ?? this.detail.player_mongo_id}`;
		},
		filteredHeaders() {
			return this.sessionsHeaders.filter((el) => !el.hide?.());
		},
	},
	methods: {
		...mapMutations("globalTicker", {
			sub: "subscribe",
			unsub: "unsubscribe",
		}),
		async onDisplayChange(e) {
			this.displayModel = e;
			await this.setPagination({
				page: 1,
				per_page: this.options.itemsPerPage,
				order: this.options.sortDesc[0] ? "-" : "+",
				order_by: this.options.sortBy[0],
			});
			this._loadTable();
		},
		exportClose(e) {
			this.exportDialog = false;
			this.$refs.exportDialog.onClose();
		},
		async _loadTable(url) {
			this.loading = true;
			try {
				if (!this.loadList) {
					this.loading = false;
					return;
				}

				if (this.displayModel === 1) {
					await this.loadRoundList({
						url,
						playerId: this.$route.params.player_id,
					});
				} else if (this.displayModel === 0) {
					await this.loadList({
						url,
						playerId: this.$route.params.player_id,
					});
				}

				this.loading = false;
			} catch (e) {
				if (e?.message === "canceled") {
					return;
				}
				this.loading = false;
			}
		},
		timeSetter() {
			this.timeNow = Date.now();
		},
		onExportOpen() {
			sessionStorage.setItem("logan2:exportDialog:hide", this.dontShow);
			this.exportDialog = true;
			setTimeout(() => {
				this.$refs.exportDialog.onExportOpen();
			}, 500);
		},

		async useFilter() {
			if (this.options.page != 1) this.options.page = 1;
			console.log(this.filter);

			this.setFilter({ ...this.filter });
			await this._loadTable();
		},
		...mapActions({
			setMessage: "notification/setMessage",
		}),
		copySession(sessionId) {
			this.timeout = this.timeout == 1000 ? 1001 : 1000;
			this.copied = true;
			navigator.clipboard.writeText(sessionId);
		},
		async reloadSessions() {
			this.loading = true;
			if (this.options.page != 1) {
				this.options.page = 1;
				return;
			}
			const order_by = this.options.sortBy[0];
			this.setPagination({
				page: 1,
				per_page: this.options.itemsPerPage,
				order: this.options.sortDesc[0] ? "-" : "+",
				order_by: order_by,
			});
			await this._loadTable();
			this.loading = false;
		},
		offerClick(e) {
			this.$router.push({
				name: "free-game-detail",
				params: {
					offer_id: e.offer_code,
					breadcrumbs: { title: `Offer ${e.offer_code}` },
				},
			});
		},
		clearFilter() {
			this.$refs.filter.onClear();
			this.useFilter();
		},
		itemClass() {
			return `table-item${this.rowIsClickable ? " table-item--clickable" : ""}`;
		},
		...mapActions("players2", {
			loadPlayerDetail: "loadDetail",
		}),
		...mapActions("freeGames", {
			loadOffers: "loadOffersPerPlayer",
		}),
		...mapActions(["setBreadCrumbs"]),
		...mapActions("rounds2", {
			loadRoundList: "loadListByPlayer",
			roundsfirst: "first",
			roundslast: "last",
			roundsnext: "next",
			roundsprevious: "previous",
		}),
		...mapActions("sessions2", {
			loadList: "loadListByPlayer",
			first: "first",
			last: "last",
			next: "next",
			previous: "previous",
		}),
		...mapMutations("rounds2", {
			setRoundsPagination: "pagination",
		}),
		...mapMutations("sessions2", {
			setSessionPagination: "pagination",
			setFilter: "filter",
		}),
		async setPagination() {
			if (this.displayModel === 0) {
				await this.setSessionPagination({
					page: 1,
					per_page: this.options.itemsPerPage,
					order: this.options.sortDesc[0] ? "-" : "+",
					order_by: this.options.sortBy[0],
				});
			} else if (this.displayModel === 1) {
				await this.setRoundsPagination({
					page: 1,
					per_page: this.options.itemsPerPage,
					order: this.options.sortDesc[0] ? "-" : "+",
					order_by: this.options.sortBy[0],
				});
			}
		},
		handleClick(item, _, e) {
			if (!this.rowIsClickable) return;
			const sessionId = item.is_step_slot
				? `${item.token}-60dl2-${item.session_mongo_id}`
				: item.session_mongo_id;
			if (e.ctrlKey === true || e.metaKey === true) {
				const tempRoute = this.$router.resolve({
					name: "session-detail",
					params: {
						session_id: sessionId,
						casino: this.currentCasino,
						breadcrumbs: { title: `Session ${sessionId}` },
					},
				});
				window.open(tempRoute.href);
			} else {
				this.$router.push({
					name: "session-detail",
					params: {
						session_id: sessionId,
						casino: this.currentCasino,
						breadcrumbs: { title: `Session ${sessionId}` },
					},
				});
			}
		},
		shouldShortenString,
	},
	created() {
		this.$syncWithStorage("filter", this.$route.name);
		this.$syncAndAssign(this.$route.name, "displayModel");
		this.filter = this.$getFromStorage("filter") ?? this.filter;
		this.setFilter({ ...this.filter });
	},
	mounted() {
		this.sub(this.timeSetter);
	},
	beforeDestroy() {
		this.unsub(this.timeSetter);
	},
	watch: {
		"$route.params.player_id": {
			async handler() {
				await this.loadPlayerDetail({
					player_id: this.$route.params.player_id,
				});
				await this.loadOffers({
					playerId: this.$route.params.player_id,
					noErr: true,
				});
			},
		},
	},
};
</script>
<style scoped>
.animated {
	transition: transform 0.3s ease-in-out;
}
.spin-me {
	transform: rotate(180deg);
}
::v-deep .v-tab::before {
	border-radius: 5px !important;
}
</style>
